import axios from '@/api/axios'
import { AccountModel } from './models/AccountModel'

class AccountService {
  async getAccount(): Promise<AccountModel[]> {
    try {
      const response = await axios.get('api/profi/v1/account')
      return response.data.data
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }
}

export default new AccountService()
